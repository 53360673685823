import store from "@/stores";

/*
Dynamic base path so we can:
- Build these routes separately to current DIE routes.
- Easily change it later if we want to.
*/
const basePath = "data-ingestion";

const routes = [
  {
    path: `/${basePath}`,
    component: () => import('@/layouts/DataManagement.vue'),
    name: `${basePath}`,
    meta: { adminOnly: true, title: "Data Ingestion" },
  },
  {
    path: `/${basePath}/upload`,
    component: () => import('@c/Manager/ingestion/HoldingArea/HoldingAreaListPage'),
    name: `${basePath}:upload`,
    meta: { adminOnly: true, title: "Upload & Integrate | Data Ingestion" },
  },
  {
    path: `/${basePath}/stage-two`,
    component: () => import("@c/Manager/ingestion/IngestionUpload"),
    name: `${basePath}:stage-two`,
    meta: { adminOnly: true, title: "Stage 2 | Data Ingestion" },
  },
  {
    path: `/${basePath}/edit/ip/:ingestionProcessId`,
    component: () => import('@c/Manager/ingestion/HoldingArea/HoldingAreaViewAndEditDataPage'),
    name: `${basePath}:edit:ip`,
    meta: { adminOnly: true },
    beforeEnter: (to, from, next) => {
      const { ingestionProcessId } = to.params;
      store.dispatch("DIE/addRecentlyAccessedIngestionProcess", ingestionProcessId)
      next();
    },
  },
  {
    path: `/${basePath}/upload`,
    component: () => import('@/pages/DataManagement/UploadData.vue'),
    name: `${basePath}:upload`,
    meta: { adminOnly: true },
  },
  {
    path: `/${basePath}/processing`,
    component: () => import('@/pages/DataManagement/Processing.vue'),
    name: `${basePath}:processing`,
    meta: {
      adminOnly: true,
      title: "Processing | Data Ingestion",
    },
  },
  {
    path: `/${basePath}/processing/summary`,
    component: () => import('@/pages/DataManagement/Processing.vue'),
    name: `${basePath}:processing:summary`,
    meta: {
      adminOnly: true,
      title: "Processing Summary | Data Ingestion",
    },
  },
];

export { routes };
